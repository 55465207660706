import React from "react";

import Layout from "../../components/layout";
import { Seo } from "../../components";

import image26 from "../../images/about/image26.png";

const DiocesanLogoMotto = ({ location }) => {
  return (
    <Layout location={location}>
      <Seo title="Diocesan Logo and Motto" />
      <div className="about">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <h3 className="about-subtitle">Diocesan Logo and Motto</h3>
              <div className="row">
                <div className="col">
                  <div className="clearfix">
                    <img
                      src={image26}
                      className="col-12 col-md-2 float-sm-start pe-md-2"
                      alt="image26"
                    ></img>
                    <p>
                      The Logo of the Madhya Kerala Diocese of the CSI has an
                      Anglican tradition. The cross of St Andrews has been
                      placed in front of a 'heart'. St. Andrews refused to be
                      crucified in the similar type of cross on which Jesus was
                      crucified, saying that he was not eligible to bear a cross
                      of Jesus, who was blemishless. St. Andrews, in the initial
                      stage of his discipleship, brought his brother Simon to
                      Jesus. Hence, the cross of St. Andrews in the logo stands
                      for the commitment of the church to evangelisation,
                      especially personal evangelisation.
                      <br />
                      <br />
                      A spear is shown as going through the middle of the cross
                      and the heart. This can be considered as the spear that
                      pierced the heart of Jesus on the cross.
                      <br />
                      <br />
                      On the top of the spear, a crown with twelve jewels has
                      been placed. There is no crown without the cross. The
                      twelve jewels remind the twelve Apostles.
                      <br />
                      <br />
                      On the upper side of the logo, a mitre-&nbsp;a liturgical
                      headdress worn by Anglican and Lutheran bishops- is shown.
                      The open upper side of the mitre is the indication of the
                      tongues of fire of the Holy Spirit coming down on the
                      bishops' heads and anointing them.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default DiocesanLogoMotto;
